export const taskEdit = {
  saveButton: 'Save changes',
  saveTasks: 'Save tasks',
  returnButton: 'Back to track',
  notificationButton: 'Inform of changing',
  previewModeButton: 'Preview mode',
  previewModeButtonTooltip: 'Save the changes to the module',
  previewModeButtonDisabledTooltip:
    'The task preview functionality is not available for video interview mode',
  notificationModal: {
    title: 'Inform users about the change in the description of the module',
    okButton: 'Inform',
    cancelButton: 'Cancel',
  },
  confirmModal: {
    title: 'Make sure that users are not currently studying this module.',
    content:
      'Otherwise, their answers will not be saved and they will have to retake the tasks.',
  },
  header: {
    title: 'Tasks',
    resetPassage: {
      title: 'Reset progress',
      okText: 'Confirm',
      emptyData: 'No users have passed the module task',
      searchButton: 'Find',
      cancelButton: 'Cancel',
      columns: {
        fullName: 'User',
        timeUpdated: 'Date of completion ',
      },
    },
  },
  taskDescription: {
    title: 'Module Description',
    buttons: {
      uploadVideo: {
        tooltip: 'Add video',
        url: 'Video URL (supported - Youtube, Rutube, Vimeo)',
        insert: 'Insert link',
        upload: 'Upload video',
        record: 'Record video',
        videoFormat: 'Valid formats:',
      },
      uploadFile: 'Insert file',
      uploadImage: 'Insert image',
    },
  },
  taskDetails: {
    removeModal: {
      title: 'Delete task',
      content:
        'Are you sure you want to delete the task? The user history and the progress on this task will be removed from the system.',
      okText: 'Yes',
      cancelText: 'No',
    },
    addTask: 'Add task',
    or: 'OR',
    addInterview: 'Add interview',
    testTask: 'Test task',
    textTask: 'Detailed task',
    videoTask: 'Video task',
    interview: {
      cancelBtn: 'Cancel',
      audioBtn: 'Audio question',
      videoBtn: 'Video question',
    },
    answer: {
      type: {
        title: 'Select the type of task',
        test: 'Test',
        text: 'Detailed answer',
        video: 'Video answer',
        rank: 'Ranking',
      },
      changeTypeModal: {
        title: 'Warning',
        description:
          'Please note that when changing the task type, all filled data including descriptions, keys, and answer options (for tests) for the current task will be deleted. If you do not want to lose the data, create a new task by clicking the "Add Task" button below.',
        okText: 'Change task type',
        cancelText: 'Cancel',
      },
      limitTime: 'Time to answer (min):',
      videoRecordOnly: 'Online record only',
      videoRecordTooltip:
        'If enabled, the user will not be able to attach a pre-recorded video',
      breakpoints: 'Correct answer keys:',
      test: {
        itemOptions: 'Options:',
        correctItems: 'Correct',
        enterItem: 'Select option',
        addTestItemButton: 'Add answer option',
      },
      rank: {
        itemOptions: 'Correct order of answers:',
        enterItem: 'Enter answer',
        addRankItemButton: 'Add answer',
        rankTooltip: 'User has to put the answers in the correct order (from 1 to N) from the list of options, where N is the number of options',
      },      
      answerKeys: {
        addAnswerKey: 'Add answer key',
        answerKeysEditing: 'Answer keys:',
        answerKeysTooltip: 'The user response must contain the keys entered',
        answerKeysReviewTask: 'Select the keys, mentioned in the answer:',
        answerKeyPlaceholder: 'Answer key :OrderNumber',
        minAnswerKeysCount: {
          start: 'The answer will be accepted by the system if ',
          end: ' / :MaxCount answer keys are marked',
          tooltip:
            'If the number of answer keys marked by the Mentor while checking the task is less than the one specified in this field, the whole task completed by the user will be rejected',
        },
      },
    },
    notification: {
      task: 'Task',
    },
  },
  settings: {
    title: 'Tasks settings',
    displayQuestionCount: 'Display the count of questions:',
    passThreshold: 'Pass threshold (for tests only):',
    leadTime: 'Estimated time (min):',
    limitTime: 'Limit time',
    limitTimeTooltipText:
      "Enable to specify time that will be allowed for users to complete the task. If the time expires, the task will be automatically submitted for checking.\nThe limit isn't available if there are question with video answers in the task.",
    mandatoryNode: 'Start the next module without checking',
    mandatoryNodeTooltip:
      'If enabled, user can start completing the following modules even if this module is not checked yet',
    nonQuestions: 'First add the tasks',
  },
  changeConfirmBtnText: {
    description: 'Edit the button:',
    hint:
      'When field is empty, a button with the text "Confirm Training" will be shown',
  },
}
