export const taskEdit = {
  saveButton: 'Сохранить изменения',
  saveTasks: 'Сохранить задания',
  returnButton: 'Вернуться к треку',
  notificationButton: 'Сообщить об изменении',
  previewModeButton: 'Режим предпросмотра',
  previewModeButtonTooltip: 'Сохраните изменения в модуле',
  previewModeButtonDisabledTooltip:
    'Функционал предпросмотра задания недоступен для режима видеособеседования',
  notificationModal: {
    title: 'Сообщить пользователям об изменении описания модуля',
    okButton: 'Сообщить',
    cancelButton: 'Отмена',
  },
  confirmModal: {
    title:
      'Убедитесь, что пользователи в текущий момент не проходят данный модуль.',
    content:
      'В противном случае, их ответы не будут сохранены, модуль придется пройти заново.',
  },
  header: {
    title: 'Задания',
    resetPassage: {
      title: 'Обнулить прохождение',
      okText: 'Подтвердить',
      emptyData: 'Нет пользователей прошедших задание модуля',
      searchButton: 'Найти',
      cancelButton: 'Отмена',
      columns: {
        fullName: 'Пользователь',
        timeUpdated: 'Дата прохождения',
      },
    },
  },
  taskDescription: {
    title: 'Описание модуля',
    buttons: {
      uploadVideo: {
        tooltip: 'Добавить видео',
        url: 'Ссылка на видео (поддерживаются Youtube, Rutube, Vimeo)',
        insert: 'Вставить ссылку',
        upload: 'Загрузить с устройства',
        record: 'Записать видео',
        videoFormat: 'Допустимые форматы: ',
      },
      uploadFile: 'Вставить файл',
      uploadImage: 'Вставить изображение',
    },
  },
  taskDetails: {
    removeModal: {
      title: 'Удаление задания',
      content:
        'Вы действительно хотите удалить задание? История прохождения пользователей данного задания и прогресс по нему будет удален из системы.',
      okText: 'Да',
      cancelText: 'Нет',
    },
    addTask: 'Добавить задание',
    or: 'ИЛИ',
    addInterview: 'Добавить собеседование',
    testTask: 'Тестовое задание',
    textTask: 'Развёрнутое задание',
    videoTask: 'Видео-задание',
    interview: {
      cancelBtn: 'Отмена',
      audioBtn: 'Аудио вопрос',
      videoBtn: 'Видео вопрос',
    },
    answer: {
      type: {
        title: 'Выберите тип задания',
        test: 'Тест',
        text: 'Развернутый ответ',
        video: 'Видео ответ',
        rank: 'Ранжирование',
      },
      changeTypeModal: {
        title: 'Предупреждение',
        description:
          'Обратите внимание, при изменении типа задания все заполненные данные, включая описания, ключи и варианты ответов (для тестов) для текущего задания будут удалены. Если вы не хотите потерять данные, создайте новое задание, нажав кнопку "Добавить задание" ниже',
        okText: 'Изменить тип задания',
        cancelText: 'Отмена',
      },
      limitTime: 'Время на ответ (мин):',
      videoRecordOnly: 'Только онлайн-запись',
      videoRecordTooltip:
        'Если свойство включено - пользователь не сможет прикрепить предварительно записанное видео',
      breakpoints: 'Контрольные точки правильного ответа:',
      test: {
        itemOptions: 'Варианты ответов:',
        correctItems: 'Правильный',
        enterItem: 'Введите вариант ответа',
        addTestItemButton: 'Добавить вариант ответа',
      },
      rank: {
        itemOptions: 'Правильный порядок ответов:',
        enterItem: 'Введите ответ',
        addRankItemButton: 'Добавить ответ',
        rankTooltip: 'Пользователю необходимо в правильном порядке расположить ответы из списка позиций от 1 до N, где N - количество вариантов ответов',
      },
      answerKeys: {
        addAnswerKey: 'Добавить ключ ответа',
        answerKeysEditing: 'Ключи правильного ответа:',
        answerKeysTooltip:
          'Ответ пользователя должен содержать введенные ключи',
        answerKeysReviewTask: 'Отметьте ключи, упомянутые в ответе:',
        answerKeyPlaceholder: 'Пункт :OrderNumber',
        minAnswerKeysCount: {
          start: 'Ответ будет принят системой, если отмечено ',
          end: ' / :MaxCount ключей',
          tooltip:
            'При проверке задания, если количество отмеченных Ментором ключей будет меньше указанного в этом поле, всё задание, выполненное пользователем, не будет засчитано',
        },
      },
    },
    notification: {
      task: 'Задание',
    },
  },
  settings: {
    title: 'Настройки заданий',
    displayQuestionCount: 'Отобразить кол-во вопросов:',
    passThreshold: 'Порог прохождения (только для тестов):',
    leadTime: 'Время выполнения (мин):',
    limitTime: 'Ограничить время',
    limitTimeTooltipText:
      'Если свойство включено - пользователям на выполнение данного задания будет отведено только указанное количество минут. При истечении времени, задание будет отправлено на проверку автоматически.\nОграничение недоступно при наличии видео-ответов в задании.',
    mandatoryNode: 'Приступить к следующему модулю без проверки',
    mandatoryNodeTooltip:
      'Если свойство включено – пользователь не будет ожидать результатов проверки отправленного задания этого модуля, а сможет перейти к выполнению следующих модулей.',
    nonQuestions: 'Сначала добавьте задания',
  },
  changeConfirmBtnText: {
    description: 'Редактировать кнопку:',
    hint:
      'В случае, если поле пустое, на странице будет выведена кнопка с текстом "Подтвердить ознакомление"',
  },
}
