export const notification = {
  general: {
    DEFAULT: {},
    ERROR: {
      retrievingList: 'При получении списка произошла ошибка!',
      corruptOrEmptyFile: 'Файл поврежден или пустой',
      unknownKey: 'Неизвестный ключ: :StateKey',
      unknownValue: 'В объекте присутствуют неизвестные свойства: :Value',
      wrongArgument: 'Получен неверный аргумент: :Argument',
      accidentalError: 'Случайная ошибка',
      unknownError: 'Неизвестная ошибка',
      unknownAthorities: 'Получены неизвестные разрешения',
      wrongResponse: 'Получен неверный ответ или не соответствующий формату',
      token: 'Ошибка разбора токена',
      connectionError: 'Ошибка соединения с сервером',
      sessionExpired: 'Сессия истекла!',
      notAuthorities: 'Вы не имеете прав на выполнение данной операции!',
      userLocked: 'Данная учетная запись заблокирована',
      wrongLoginPassword: 'Имя пользователя или пароль неверные!',
      userUndefined: 'Пользователь с таким e-mail не найден',
      corruptOrEmpty: 'Файл поврежден или пустой',
      message: 'Данная учетная запись заблокирована',
      tokenExpired: 'Истек срок действия токена',
    },
    SUCCESS: {
      file: 'Файл',
      uploadedSuccessfully: 'загружен успешно',
    },
    WARNING: {
      awaitThenUploaded: 'Дождитесь окончания загрузки текущего файла',
    },
    INFO: {},
  },
  task: {
    DEFAULT: {},
    ERROR: {
      taskNotFoundId: 'Задание не найдено: ID=:Index',
      topicNotFound: 'Поле "Описание модуля" не должно быть пустым',
      moduleTaskNotFound: 'Задание модуля не найдено',
      usersNotFound: 'Пользователей не найдено',
      testNotFound: 'В данном задании отсутствуют тесты',
      rankNotFound: 'В данном задании отсутствуют ранжирования',
      answerNotFound:
        'Ответ не найден: задание ID = :Index, тест index = :TestIndex',
      answerRankNotFound:
        'Ответ не найден: задание ID = :Index, ранжирование index = :RankIndex',
      answerKeysNotFound: 'В данном задании отсутствуют контрольные ключи',
      answerKeyNotFound:
        'Контрольный ключ не найден: задание ID = :Index, пункт index = :AnswerKeyIndex',
      unknownResult: 'Неизвестный результат отправки задания: :Data',
      questionNotFound: 'Вопрос не найден: ID=:Index',
      taskNotFound: 'Задание не найдено',
      draftNotFound: 'Черновик не найден',
      incorrectAnswer: 'Некорректный тип ответа: :Type',
    },
    SUCCESS: {},
    WARNING: {
      blockNavigation:
        'Страница содержит несохранённые изменения. Вы действительно хотите покинуть страницу?',
    },
    INFO: {},
  },
  item: {
    DEFAULT: {},
    ERROR: {
      dataNotFound: 'Данные треки не найдены',
    },
    SUCCESS: {
      edited: 'Трек: :title успешно отредактирован',
      added: 'Трек: :title успешно добавлен',
      deleted: 'Трек удален',
    },
    WARNING: {},
    INFO: {},
  },
  area: {
    DEFAULT: {},
    ERROR: {
      dataNotFound: 'Данные папки не найдены',
    },
    SUCCESS: {
      edited: 'Папка: ":title" успешно отредактирована',
      added: 'Папка: ":title" успешно добавлена',
      deleted: 'Папка удалена',
    },
    WARNING: {},
    INFO: {},
  },
  validation: {
    DEFAULT: {},
    ERROR: {
      emptyAnswers: 'Не на все вопросы указаны ответы',
      maxFilesSize: 'Суммарный размер файлов не должен превышать :MaxSize MB!',
      maxFileSize: 'Размер файла должен быть менее :MaxSize MB!',
      format: 'Формат файла должен быть :ValidFormat!',
      invalidFormat: 'Некорректный формат (только :ValidFormat)',
      maxSizeImg: 'Размер изображения должен быть менее :MaxSize MB!',
      maxSizeFile: 'Размер файла должен быть менее :MaxSize MB!',
      lessThan: 'До :MaxSize МБ',
      maxUploadVideo: '*Максимальный размер загружаемого видео - :MaxSize MB',
      limitTime: 'Видео не должно превышать ограничение продолжительности',
      oversize: 'Размер файла больше :MaxSize MB!',
      sendVideoAnswer: 'Нажмите на кнопку "Отправить видео"',
      wrongSupportEmail:
        'Не привязан Support email или указан неверно email. Обратитесь к администратору!',
      requiredTask: 'Описание задания обязательно к заполнению!',
      requiredVideoTask: 'Загрузка или запись видео-задания обязательна!',
      requiredItem: 'Описание ответа :Order обязательно к заполнению!',
      requiredItems: 'Должен содержать минимум 2 варианта ответа!',
      rightAnswers: 'Задание не содержит ни одного правильного ответа!',
      uniqAnswers:
        'Вариант ответа :TestItem уже присутствует в тестовом задании!',
      requiredAnswerKey: 'Описание ключей ответа обязательно к заполнению!',
      uniqAnswerKeys: 'Текст ключей ответа совпадает - :AnswerKey!',
      videoLoaded: 'Видео еще загружается. Подождите еще немного.',
    },
    SUCCESS: {
      feedBackPassed: 'Обращение отправлено',
    },
    WARNING: {},
    INFO: {},
  },
  auth: {
    DEFAULT: {},
    ERROR: { invalidLink: 'Недействительная ссылка' },
    SUCCESS: {
      authorization: 'Успешная авторизация',
      accountConfirmed: 'Учетная запись подтверждена',
      passwordChanged: 'Пароль изменен',
      recoveryPassword: 'Сброс пароля произведен успешно',
      registration: 'Успешная регистрация',
    },
    WARNING: {},
    INFO: {},
  },
  positions: {
    DEFAULT: {},
    ERROR: {
      dataNotFound: 'Данные должности не найдены',
    },
    SUCCESS: {
      edited: 'Должность: :Title успешно отредактирована',
      added: 'Должность: :Title успешно добавлена',
      deleted: 'Должность удалена',
    },
    WARNING: {},
    INFO: {},
  },
  settings: {
    DEFAULT: {},
    ERROR: {},
    SUCCESS: {
      saved: 'Настройки успешно сохранены',
      passwordChanged: 'Пароль успешно изменен',
      emailChanged: 'Email успешно изменен',
      phoneNumberChanged: 'Номер телефона успешно изменен',
    },
    WARNING: {},
    INFO: {},
  },
  verification: {
    DEFAULT: {},
    ERROR: {},
    SUCCESS: {
      videoAdded: 'Видео успешно прикреплено',
      postZeroPassed: 'Прохождение задания обнулено',
      avatarChange: 'Аватарка изменена',
      confirmed: 'Выполнение задания принято',
      sentForReview: 'Задание отправлено на проверку системы',
      rejected: 'Выполнение задания отклонено',
      updateNode: 'Изменения сохранены',
      postTopicChanged: 'Пользователи уведомлены об изменении описания',
    },
    WARNING: {},
    INFO: {},
  },
  track: {
    engine: {
      DEFAULT: {},
      ERROR: {
        oneRoot: 'В структуре должен быть один корневой модуль',
        recursion: 'В структуре не должно быть зацикленных связей',
        emptyNodeTitle: 'Наименование модуля не может быть пустым!',
        duplicateNodeTitle: 'Имя модуля должно быть уникальным',
        diagramNodesError: 'Не удалось построить диаграмму тарелок',
        requestError: 'Ошибка при получении данных от сервера',
      },
      SUCCESS: {
        saveNodes: 'Изменения успешно сохранены!',
        copied: 'Скопировано',
        export: 'Экспорт завершен',
      },
      WARNING: {
        blockCopy:
          'Сохраните трек. Вы пытаетесь скопировать несохраненный(-ые) модуль(-и)',
        topicNotFound: 'Поле "Описание модуля" не должно быть пустым',
      },
      INFO: {
        maxLengthNodeTitle:
          'Максимальная длина названия модуля - :MaxChars символов',
        addNode:
          'Для добавления модуля нажмите мышкой на свободное место рабочей области',
        addCurve:
          'Для связывания нажмите на модуль, который хотите связать с текущим модулем',
        addNodeTitle: 'Введите название нового модуля',
      },
    },
    DEFAULT: {},
    ERROR: {},
    SUCCESS: {
      cardEdit: 'Карточка трека изменена',
      import: 'Импорт завершен',
    },
    WARNING: {},
    INFO: {},
  },
  users: {
    DEFAULT: {},
    ERROR: {
      dataNotFound: 'Данные пользователя не найдены',
      wrongDict: 'Получен неверный аргумент: :DefaultItemsDict',
    },
    SUCCESS: {
      edited: 'Пользователь: :fullName успешно отредактирован',
      added: 'Пользователь: :fullName успешно добавлен',
      deleted: 'Пользователь удален',
      resetPassword: 'Пароль успешно обнулен',
      changeEmail: 'Email успешно изменен',
    },
    WARNING: {},
    INFO: {},
  },
}
