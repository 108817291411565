import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RcFile } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import _ from 'lodash'
import * as I from 'Types'
import { processError } from '../../app/processError'
import { stackRequestWrapper } from '../../app/requestWrapper'
import {
  EAPIResponseStatus,
  EPassResult,
  EQuestionType,
  ETaskState,
} from '../../constants'
import FileService from '../../services/file/file.service'
import { IAPIResult } from '../../services/IBaseService'
import NodesService from '../../services/nodes/nodes.service'
import TaskPassingService from '../../services/tasks/tasks-passing/tasks-passing.service'
import { setTimeout } from 'timers'
import local from './../../localization'

const initialState: I.INodePassState = {
  task: {
    nodeId: 0,
    passThreshold: 0,
    minutesEstimated: 0,
    limitTime: false,
    isInterviewDone: false,
    questions: [],
    status: null,
  },
  draft: {
    nodeId: 0,
    timeStarted: 0,
    answers: [],
  },
}

const redirectToTopic = () =>
  (window.location.href = window.location.href.replace('/start', ''))

export const getTrackNodes = createAsyncThunk(
  'tasks/pass/track_nodes',
  async (trackId: number, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(NodesService.getNodes(trackId))

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getPassTask = createAsyncThunk(
  'tasks/pass/task',
  async (nodeId: number, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        TaskPassingService.getNodeTask(nodeId)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getPassTaskQuestions = createAsyncThunk(
  'tasks/pass/task/questions',
  async (nodeId: number, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        TaskPassingService.getNodeTaskQuestions(nodeId)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getPassTaskStatus = createAsyncThunk(
  'tasks/pass/status',
  async (nodeId: number, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(
        NodesService.getNodeStatus(nodeId)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const sendTaskSolution = createAsyncThunk(
  'tasks/pass/solutions/send',
  async (
    data: { task: I.INodePassDraft; callback: (type: EPassResult) => void },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const result = await stackRequestWrapper(
        TaskPassingService.postTaskSolution(data.task)
      )

      if (
        result.data?.status &&
        _.map(EPassResult, k => k).includes(result.data.status)
      ) {
        data.callback(result.data.status)
      } else {
        throw new Error(
          local.notification.task.ERROR.unknownResult.replace(
            ':Data',
            `${result.data}`
          )
        )
      }

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const uploadFileBinary = createAsyncThunk(
  'tasks/pass/task/file',
  async (file: File, { rejectWithValue, dispatch }) => {
    try {
      const result = await stackRequestWrapper(FileService.postBinaryFile(file))

      return result.data?.url
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const getPassTaskDraft = createAsyncThunk(
  'tasks/pass/draft',
  async (nodeId: number, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as I.RootState
      const isInterview = selectPassingInterviewStatus(state)
      const draftPlug = state.tasks.pass.draft

      if (isInterview && state.tasks.pass.task.status === ETaskState.REJECTED) {
        return { status: EAPIResponseStatus.SUCCESS, data: draftPlug }
      }

      const result = await stackRequestWrapper(
        TaskPassingService.getTaskPassingDraft(nodeId)
      )
      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const setPassTaskDraft = createAsyncThunk(
  'tasks/pass/draft/set',
  async (draft: I.INodePassDraft, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as I.RootState
      const isInterview = selectPassingInterviewStatus(state)
      const draftPlug = state.tasks.pass.draft

      if (isInterview && state.tasks.pass.task.status === ETaskState.REJECTED) {
        return { status: EAPIResponseStatus.SUCCESS, data: draftPlug }
      }

      const result = await stackRequestWrapper(
        TaskPassingService.setTaskPassingDraft(draft)
      )

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const setDelayedVideoAnswers = createAsyncThunk(
  'tasks/pass/delayedVideo/set',
  async (
    data: { task: I.INodePassTask; draft: I.INodePassDraft },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { draft } = data
      const result = await stackRequestWrapper(
        TaskPassingService.setTaskPassingDraft(draft)
      )
      dispatch(setDraft(draft))

      return result
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const changeAnswerFile = createAsyncThunk<
  void,
  {
    questionId: number
    deleted: boolean
    file: Partial<RcFile | UploadFile<any>>
  },
  { state: I.RootState }
>(
  'tasks/pass/draft/set',
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const { draft, task } = getState().tasks.pass
      const answers = _.cloneDeep(draft.answers)

      const question = task.questions.find(q => q.id === data.questionId)
      if (!question) {
        throw new Error(
          local.notification.task.ERROR.questionNotFound.replace(
            ':Index',
            `${data.questionId}`
          )
        )
      }

      let answer = answers.find(a => a.questionId === data.questionId)

      if (data.deleted && answer) {
        answer.fileUrls = answer.fileUrls?.filter(
          f => f.url !== (data.file as UploadFile<any>).url
        )
      } else {
        const result = await stackRequestWrapper(
          FileService.postBinaryFile(data.file as RcFile)
        )
        if (result.data) {
          if (!answer) {
            answer = {
              questionId: data.questionId,
              fileUrls: [result.data],
            }
            answers.push(answer)
          } else {
            if (!answer.fileUrls) {
              answer.fileUrls = [result.data!]
            } else if (
              answer.fileUrls?.length! <
              Number(window.REACT_APP_FILE_MAX_COUNT_PER_ANSWER)
            ) {
              answer.fileUrls?.push(result.data!)
            }
          }
        }
      }
      dispatch(setDraft({ ...draft, answers }))
      dispatch(setPassTaskDraft({ ...draft, answers }))
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const putSolutionAttemptReject = createAsyncThunk(
  'tasks/pass/solution/reject',
  async (taskId: number, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as I.RootState
      const isInterview = selectPassingInterviewStatus(state)

      if (
        isInterview &&
        state.tasks.pass.draft.id &&
        state.tasks.pass.draft.answers.some(
          answer =>
            !Object.keys(answer).includes('video') || answer.video === null
        ) &&
        state.tasks.pass.task.status !== ETaskState.REJECTED
      ) {
        const result = await stackRequestWrapper(
          TaskPassingService.putSolutionAttemptReject(taskId)
        )

        return result
      }
    } catch (e) {
      dispatch(processError({ e }))
      return rejectWithValue('')
    }
  }
)

export const updateUuidAnswer = createAsyncThunk(
  'tasks/pass/updateUuidAnswer',
  async (
    {
      questionId,
      newUuidAnswer,
    }: { questionId: number; newUuidAnswer: Record<number, number> },
    { dispatch, getState }
  ) => {
    const state = getState() as I.RootState
    const draft = _.cloneDeep(state.tasks.pass.draft)
    const answer = draft.answers.find(a => a.questionId === questionId)
    if (answer) {
      answer.uuidAnswers = newUuidAnswer
    }
    dispatch(setDraft(draft))
    dispatch(setPassTaskDraft(draft))
    return { questionId, newUuidAnswer }
  }
)

export const tasksPassingSlice = createSlice({
  name: 'tasks/pass',
  initialState,
  reducers: {
    resetTaskPassingStore: (state: I.INodePassState) => {
      _.assign(state, initialState)
    },
    setDraft: (
      state: I.INodePassState,
      { payload }: PayloadAction<Partial<I.INodePassDraft>>
    ) => {
      _.assign(state.draft, payload)
    },
    setVideoAnswer: (
      state: I.INodePassState,
      { payload }: PayloadAction<I.INodeVideoAnswer>
    ) => {
      const { questionId, video } = payload

      const answers = state.draft.answers

      const answ = answers.find(a => a.questionId === questionId)

      if (answ) {
        answ.video = video
      } else {
        const answer = {
          questionId,
          video,
        }
        answers.push(answer)
      }
    },
    setTask: (
      state: I.INodePassState,
      { payload }: PayloadAction<I.INodePassTask>
    ) => {
      _.assign(state.task, payload)
    },
    setIsInterviewDone: (
      state: I.INodePassState,
      action: PayloadAction<boolean>
    ) => {
      state.task.isInterviewDone = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getPassTask.fulfilled, (state, action) => {
        if (action.payload?.data) {
          const {
            limitTime,
            minutesEstimated,
            nodeId,
            passThreshold,
          } = action.payload.data
          _.assign(state.task, {
            limitTime,
            minutesEstimated,
            nodeId,
            passThreshold,
          })
          state.draft.nodeId = action.payload.data.nodeId
        } else {
          console.error(local.notification.task.ERROR.taskNotFound)
        }
      })
      .addCase(getPassTaskQuestions.fulfilled, (state, action) => {
        if (action.payload?.data) {
          state.task.questions = action.payload.data
          if (!state.draft.answers.length) {
            state.draft.answers = action.payload.data.map(q => ({
              questionId: q.id!,
              checkedItemsIds: [],
              uuidAnswers: {},
              fileUrls: [],
              hidden: q.hidden,
            }))
          }
        } else {
          console.error(local.notification.task.ERROR.taskNotFound)
        }
      })
      .addCase(
        getPassTaskStatus.fulfilled,
        (
          state,
          { payload }: PayloadAction<IAPIResult<I.INodeStatusRequest>>
        ) => {
          state.task.status = payload.data?.status || ETaskState.UNAVAILABLE
          state.task.isTrackAvailable = payload.data?.isTrackAvailable
        }
      )
      .addCase(
        getPassTaskDraft.fulfilled,
        (state, { payload }: PayloadAction<IAPIResult<I.INodePassDraft>>) => {
          if (payload?.data) {
            _.assign(state.draft, {
              ...payload.data,
              answers: payload.data.answers.filter(a =>
                state.task.questions.some(q => a.questionId === q.id)
              ),
            })
          } else {
            console.error(local.notification.task.ERROR.draftNotFound)
          }
        }
      )
      .addCase(setPassTaskDraft.fulfilled, (state, action) => {
        if (action.payload) {
          state.draft.id = action.payload.data?.id
        }
      })
      .addCase(setDelayedVideoAnswers.fulfilled, (state, action) => {
        if (action.payload) {
          state.draft.id = action.payload.data?.id
        }
      })
      .addCase(getPassTaskDraft.rejected, () => {
        setTimeout(() => redirectToTopic(), 3000)
      })
      .addCase(setPassTaskDraft.rejected, () => {
        setTimeout(() => redirectToTopic(), 3000)
      })
      .addCase(sendTaskSolution.rejected, () => {
        setTimeout(() => redirectToTopic(), 3000)
      })
  },
})

export const selectTask = (state: I.RootState) => state.tasks.pass.task

export const selectDraft = (taskId: number) => (state: I.RootState) => ({
  ...state.tasks.pass.draft,
  nodeId: taskId,
})

export const selectPassingInterviewStatus = (state: I.RootState) =>
  state.tasks.pass.task.questions.some(
    q => q.questionType === EQuestionType.VIDEO_INTERVIEW
  )

export const {
  resetTaskPassingStore,
  setDraft,
  setVideoAnswer,
  setTask,
  setIsInterviewDone,
} = tasksPassingSlice.actions
export const { reducer: tasksPassingReducer } = tasksPassingSlice
