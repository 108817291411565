export const notification = {
  general: {
    DEFAULT: {},
    ERROR: {
      retrievingList: 'An error occurred while retrieving the list!',
      corruptOrEmptyFile: 'File is corrupt or empty',
      unknownKey: 'Unknown key: :StateKey',
      unknownValue: 'Unknown properties are present in the object: :Value',
      wrongArgument: 'Invalid argument received: :Argument',
      accidentalError: 'Accidental error',
      unknownError: 'Unknown error',
      unknownAthorities: 'Unknown permissions received',
      wrongResponse: 'Invalid or non-conforming response received',
      token: 'Error parsing token',
      connectionError: 'Error connecting to server',
      sessionExpired: 'Session expired!',
      notAuthorities: 'You do not have permission to perform this operation!',
      userLocked: 'This account is locked',
      wrongLoginPassword: 'Invalid username or password!',
      userUndefined: 'A user with this email was not found',
      corruptOrEmpty: 'File is corrupt or empty',
      message: 'User account is locked',
      tokenExpired: 'Refresh token expired.',
    },
    SUCCESS: {
      file: 'File',
      uploadedSuccessfully: 'uploaded successfully',
    },
    WARNING: {
      awaitThenUploaded: 'Wait for the current file to be downloaded',
    },
    INFO: {},
  },
  task: {
    DEFAULT: {},
    ERROR: {
      taskNotFoundId: 'Task not found: ID=:Index',
      topicNotFound: 'The "Module Description" field must not be empty',
      moduleTaskNotFound: 'Module task not found',
      usersNotFound: 'No users found',
      testNotFound: 'There are no tests in this task',
      rankNotFound: 'There are no rankings in this task',
      answerNotFound:
        'No answer found: task ID =:Index, test index = :TestIndex',
      answerRankNotFound:
        'No answer found: task ID =:Index, rank index = :RankIndex',
      answerKeysNotFound: 'Answer keys are missing in this task',
      answerKeyNotFound:
        'Answer key not found: task ID = :Index, answer key index = :AnswerKeyIndex',
      unknownResult: 'Unknown task submission result: :Data',
      questionNotFound: 'Question not found: ID=:Index',
      taskNotFound: 'Task not found',
      draftNotFound: 'Draft not found',
      incorrectAnswer: 'Incorrect answer type: :Type',
    },
    SUCCESS: {},
    WARNING: {
      blockNavigation:
        'The page contains unsaved changes. Are you sure you want to leave the page?',
    },
    INFO: {},
  },
  item: {
    DEFAULT: {},
    ERROR: {
      dataNotFound: 'Track data not found',
    },
    SUCCESS: {
      edited: 'Track: :title edited successfully',
      added: 'Track: :title added successfully',
      deleted: 'Track deleted',
    },
    WARNING: {},
    INFO: {},
  },
  area: {
    DEFAULT: {},
    ERROR: {
      dataNotFound: 'Folder data not found',
    },
    SUCCESS: {
      edited: 'Folder: ":title" edited successfully',
      added: 'Folder: ":title" added successfully',
      deleted: 'Folder deleted',
    },
    WARNING: {},
    INFO: {},
  },
  validation: {
    DEFAULT: {},
    ERROR: {
      emptyAnswers: 'Not all questions are answered',
      maxFilesSize: "The total file size mustn't exceed :MaxSize MB!",
      maxFileSize: 'The File size must be less than :MaxSize MB!',
      format: 'Required file format :ValidFormat!',
      invalidFormat: 'Invalid format (only :ValidFormat)',
      maxSizeImg: 'Image size must be less than :MaxSize MB!',
      maxSizeFile: 'The File size must be less than :MaxSize MB!',
      lessThan: 'Less than :MaxSize MB',
      maxUploadVideo: '*Upload video max size - :MaxSize MB',
      limitTime: 'The video must not exceed the duration limit',
      oversize: 'File size exceeds :MaxSize MB!',
      sendVideoAnswer: 'Click the "Submit Video" button',
      wrongSupportEmail:
        'Support email is not linked or the wrong email. Contact your administrator!',

      requiredTask: 'Task description required!',
      requiredVideoTask: 'Downloading or recording a video task is required!',
      requiredItem: 'Answer description :Order is required!',
      requiredItems: 'Must contain at least 2 answer options!',
      rightAnswers: "The task doesn't contain any correct answer(s)!",
      uniqAnswers: 'The option :TestItem is already present in the test!',
      requiredAnswerKey: 'Description of the answer keys is required!',
      uniqAnswerKeys: 'The text of the answer keys matches :AnswerKey!',
      videoLoaded: 'The video is still loading. Wait a little longer.'
    },
    SUCCESS: {
      feedBackPassed: 'Message sent',
    },
    WARNING: {},
    INFO: {},
  },
  auth: {
    DEFAULT: {},
    ERROR: { invalidLink: 'Invalid link' },
    SUCCESS: {
      authorization: 'Authorization successful',
      accountConfirmed: 'Account confirmed',
      passwordChanged: 'Password has been changed',
      recoveryPassword: 'Password reset successfully',
      registration: 'Registration successful',
    },
    WARNING: {},
    INFO: {},
  },
  positions: {
    DEFAULT: {},
    ERROR: {
      dataNotFound: 'Role data not found',
    },
    SUCCESS: {
      edited: 'Role: :Title edited successfully',
      added: 'Role: :Title added successfully',
      deleted: 'Role deleted',
    },
    WARNING: {},
    INFO: {},
  },
  settings: {
    DEFAULT: {},
    ERROR: {},
    SUCCESS: {
      saved: 'Settings successfully saved',
      passwordChanged: 'Password successfully changed',
      emailChanged: 'Email successfully changed',
      phoneNumberChanged: 'Phone number successfully changed',
    },
    WARNING: {},
    INFO: {},
  },
  verification: {
    DEFAULT: {},
    ERROR: {},
    SUCCESS: {
      videoAdded: 'Video attached successfully',
      postZeroPassed: 'Task progress reset',
      avatarChange: 'Avatar has been changed',
      confirmed: 'Task completion accepted',
      sentForReview: 'The task has been submitted for system checking',
      rejected: 'Task completion rejected',
      updateNode: 'Changes saved',
      postTopicChanged: 'Users are notified of the description change',
    },
    WARNING: {},
    INFO: {},
  },
  track: {
    engine: {
      DEFAULT: {},
      ERROR: {
        oneRoot: 'There must be one root module in the structure',
        recursion: 'The structure should not have looped links',
        emptyNodeTitle: "Module title can't be empty!",
        duplicateNodeTitle: 'The module title must be unique',
        diagramNodesError: 'Unable to build map chart',
        requestError: 'Error retrieving data from server',
      },
      SUCCESS: {
        saveNodes: 'Changes saved successfully',
        copied: 'Copied',
        export: 'Export completed',
      },
      WARNING: {
        blockCopy: 'Save the track. You are trying to copy unsaved module(-s)',
        topicNotFound: 'The "Module Description" field must not be empty',
      },
      INFO: {
        maxLengthNodeTitle: 'Module title max length - :MaxChars characters',
        addNode: 'To add a module click on empty space of the work area',
        addCurve:
          'To bind click the module you want to bind to the current one',
        addNodeTitle: 'Enter module title',
      },
    },
    DEFAULT: {},
    ERROR: {},
    SUCCESS: {
      cardEdit: 'Track description changed',
      import: 'Import completed',
    },
    WARNING: {},
    INFO: {},
  },
  users: {
    DEFAULT: {},
    ERROR: {
      dataNotFound: 'No user data found',
      wrongDict: 'Invalid argument received: :DefaultItemsDict',
    },
    SUCCESS: {
      edited: 'User: :fullName successfully edited',
      added: 'User: :fullName successfully added',
      deleted: 'User deleted',
      resetPassword: 'Password successfully reset',
      changeEmail: 'Email changed successfully',
    },
    WARNING: {},
    INFO: {},
  },
}
