export const taskEdit = {
  saveButton: 'Değişiklikleri kaydet',
  saveTasks: 'Görevleri kaydet',
  returnButton: 'Rotaya geri dön',
  notificationButton: 'Değişiklik bildir',
  previewModeButton: 'Önizleme modu',
  previewModeButtonTooltip: 'Değişiklikleri işe kaydedin',
  previewModeButtonDisabledTooltip:
    'Görev önizleme işlevi video görüşme modu için kullanılamaz',
  notificationModal: {
    title: 'Modül tanımındaki değişiklik hakkında kullanıcıları bilgilendirin',
    okButton: 'Bilgilendir',
    cancelButton: 'Kaldır',
  },
  confirmModal: {
    title: 'Kullanıcıların o anda bu modülü çalışmadığından emin olun.',
    content:
      'Aksi takdirde, cevapları kaydedilmeyecek ve görevleri yeniden almaları gerekecektir',
  },
  header: {
    title: 'Görevler',
    resetPassage: {
      title: 'İlerlemeyi sıfırla',
      okText: 'Onayla',
      emptyData: 'Hiçbir kullanıcı modül görevini geçemedi',
      searchButton: 'Bul',
      cancelButton: 'Kaldır',
      columns: {
        fullName: 'Kullanıcı',
        timeUpdated: 'Tamamlanma tarihi',
      },
    },
  },
  taskDescription: {
    title: 'Modül Açıklaması',
    buttons: {
      uploadVideo: {
        tooltip: 'Video ekle',
        url: "Video URL'si (desteklenen - Youtube, Rutube, Vimeo)",
        insert: 'Link ekle',
        upload: 'Video yükle',
        record: 'Video kaydet',
        videoFormat: 'Geçerli formatlar:',
      },
      uploadFile: 'Dosya ekle',
      uploadImage: 'Görüntü ekle',
    },
  },
  taskDetails: {
    removeModal: {
      title: 'Görevi sil',
      content:
        'Görevi silmek istediğinizden emin misiniz? Kullanıcı geçmişi ve bu görevdeki ilerleme sistemden silinecek.',

      okText: 'Evet',
      cancelText: 'Hayır',
    },
    addTask: 'Görev ekle',
    or: 'VEYA',
    addInterview: 'Görüşme ekle',
    testTask: 'Test görevi',
    textTask: 'Detaylı görev',
    videoTask: 'Videolu görev',
    interview: {
      cancelBtn: 'İptal',
      audioBtn: 'Sesli soru',
      videoBtn: 'Video sorusu',
    },
    answer: {
      type: {
        title: 'Görev türünü seçin',
        test: 'Test et',
        text: 'Detaylı cevap',
        video: 'Videolu cevap',
        rank: 'Sıralama',
      },
      changeTypeModal: {
        title: 'Uyarı',
        description:
          'Dikkat: Görev tipini değiştirdiğinizde, mevcut görev için girilen tüm veriler, açıklamalar, anahtarlar ve yanıt seçenekleri (testler için) silinecektir. Verilerinizi kaybetmek istemiyorsanız, aşağıdaki "Görev ekle" düğmesine tıklayarak yeni bir görev oluşturun',
        okText: 'Görev Tipini Değiştir',
        cancelText: 'İptal',
      },
      limitTime: 'Cevaplama zamanı (dk):',
      videoRecordOnly: 'Sadece çevrimiçi kayıt',
      videoRecordTooltip:
        'Etkinleştirilirse, kullanıcı önceden kaydedilmiş bir video ekleyemez',
      breakpoints: 'Doğru cevap anahtarları:',
      test: {
        itemOptions: 'Seçenekler:',
        correctItems: 'Doğru',
        enterItem: 'Seçenek seç',
        addTestItemButton: 'Cevap seçeneği ekle',
      },
      rank: {
        itemOptions: 'Doğru cevap sırası:',
        enterItem: 'Cevap girin',
        addRankItemButton: 'Cevap ekle',
        rankTooltip: 'Kullanıcı cevapları seçenekler listesinden doğru sıraya koymalıdır (1\'den N\'ye kadar), burada N seçenek sayısıdır',
      },
      answerKeys: {
        addAnswerKey: 'Cevap anahtarı ekle',
        answerKeysEditing: 'Cevap anahtarları:',
        answerKeysTooltip: 'Kullanıcı yanıtı girilen anahtarları içermelidir',
        answerKeysReviewTask: 'Cevapta bahsedilen anahtarları seçin:',
        answerKeyPlaceholder: 'Cevap anahtarı :OrderNumber',
        minAnswerKeysCount: {
          start: 'Cevap sistem tarafından kabul edilecektir eğer ',
          end: ' / :MaxCount cevap anahtarları işaretlendiyse',
          tooltip:
            'Eğitmen tarafından görev kontrol edilirken işaretlenen cevap anahtarı sayısı bu alanda belirtilenden az ise, kullanıcının tamamladığı görevin tamamı reddedilecektir.',
        },
      },
    },
    notification: {
      task: 'Görev',
    },
  },
  settings: {
    title: 'Görev ayarları',
    displayQuestionCount: 'Soru sayısını göster:',
    passThreshold: 'Geçme sınırı (yalnızca çoktan seçmeli testler için):',
    leadTime: 'Tahmini süre (dk):',
    limitTime: 'Zaman limiti',
    limitTimeTooltipText:
      'Kullanıcıların görevi tamamlamasına izin verilecek zamanı belirtmek için etkinleştirin. Süre sona ererse, görev kontrol için otomatik olarak gönderilir.\nGörevde video yanıtlı soru varsa sınır kullanılamaz.',
    mandatoryNode: 'Kontrol sonrası modülü başlatın',
    mandatoryNodeTooltip:
      'Etkinleştirilirse, bu modül henüz kontrol edilmemiş olsa bile kullanıcı aşağıdaki modülleri tamamlamaya başlayabilir',
    nonQuestions: 'Önce görevleri ekleyin',
  },
  changeConfirmBtnText: {
    description: 'Düğmeyi düzenleyin:',
    hint:
      'Alan boş olduğunda, "Eğitimi Onayla" metnini içeren bir düğme gösterilecektir',
  },
}
